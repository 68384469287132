export default {
  "navbar": {
    "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education"])},
    "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience"])},
    "skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skills"])},
    "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "images": {
      "sun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change theme to light"])},
      "moon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change theme to dark"])},
      "polishFlag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change language to Polish"])},
      "greatBritainFlag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change language to English"])}
    }
  },
  "landingPage": {
    "myNameIs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hi, my name is Chris N."])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Life's too short to write code that nobody wants"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'm a full stack software engineer, a generalist at heart, with over 6+ years of experience building mobile apps, web apps, APIs, and microservices with the proven ability to lead small to mid size global engineering teams in order to design, implement and deliver a product."])}
  },
  "education": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education"])},
    "educationItems": {
      "zse": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondary Technical School of Electrical Engineering in Bialystok"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks to the education from this institution, I have gained a basic understanding of computer science, which not only broadened my horizons, but also gave me plenty of knowledge about databases and the development of desktop or web applications."])},
        "major": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programmer technician"])}
      },
      "universityOfTechnology": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bialystok University of Technology"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Will expand IT-related knowledge."])},
        "major": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Computer science"])}
      }
    }
  },
  "experience": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience"])},
    "experienceItems": {
      "merinosoft": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development of a web application relating to the management of employees in a company."])},
        "profession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PHP Developer"])},
        "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aug 2021 - Dec 2022 "])},
        "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(1 year 5 months)"])}
      },
      "ebrand": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development of a distributed microservices-based system relating to online brand protection. Integration with multiple external APIs."])},
        "profession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PHP Developer"])},
        "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apr 2023 - Sep 2023 "])},
        "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(6 months)"])}
      }
    }
  },
  "skills": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skills"])},
    "useRegularly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I use regularly"])},
    "hadContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I had contact with"])}
  },
  "projects": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client Work"])},
    "projectsItems": {
      "shopSystem": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop system"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backend of a simple store system. The project is based on CQRS made as a modular monolith. Directories are organized using Domain-Driven-Design approach."])}
      },
      "demoCryptoExchange": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demo crypto exchange"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A project of a demo cryptocurrency exchange that simulates the real functioning of an exchange, but uses fictional currency. Data regarding cryptocurrency prices is obtained from the API provided by the Binance exchange."])}
      },
      "snakeWebApp": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snake - web app"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This application handles data sent by the game application written in python. It has extensive user account functionality, along with a comprehensive, feature-rich admin panel."])}
      },
      "snakeGame": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snake - game"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This game reads and writes data through a web application API. The user competes against other users through a ranking, whilst earning currency that can then be spent on upgrades or skins that improve the gameplay experience."])}
      },
      "minesweeper": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minesweeper"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A sapper game with a selectable game difficulty and a save option, which saves choices as well as user records to the local browser memory."])}
      },
      "portfolio": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A website summarizing information about myself. It includes information about my education, commercial experience and projects, whilst being interactive and animated."])}
      },
      "youtubeDownloader": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YouTube Downloader"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A desktop application that allows users to download videos from YouTube. It has a tab with download settings, where the user can, among other things, choose the quality of the downloaded content and change the extension."])}
      }
    }
  },
  "portfolio": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorite Projects"])},
    "projectsItems": {
      "shopSystem": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop system"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backend of a simple store system. The project is based on CQRS made as a modular monolith. Directories are organized using Domain-Driven-Design approach."])}
      },
      "demoCryptoExchange": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demo crypto exchange"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A project of a demo cryptocurrency exchange that simulates the real functioning of an exchange, but uses fictional currency. Data regarding cryptocurrency prices is obtained from the API provided by the Binance exchange."])}
      },
      "snakeWebApp": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snake - web app"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This application handles data sent by the game application written in python. It has extensive user account functionality, along with a comprehensive, feature-rich admin panel."])}
      },
      "snakeGame": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snake - game"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This game reads and writes data through a web application API. The user competes against other users through a ranking, whilst earning currency that can then be spent on upgrades or skins that improve the gameplay experience."])}
      },
      "minesweeper": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minesweeper"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A sapper game with a selectable game difficulty and a save option, which saves choices as well as user records to the local browser memory."])}
      },
      "portfolio": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A website summarizing information about myself. It includes information about my education, commercial experience and projects, whilst being interactive and animated."])}
      },
      "youtubeDownloader": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YouTube Downloader"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A desktop application that allows users to download videos from YouTube. It has a tab with download settings, where the user can, among other things, choose the quality of the downloaded content and change the extension."])}
      }
    }
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])}
  },
  "footer": {
    "allRightsReserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["© All rights reserved"])}
  },
  "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chris - AskChris LLC"])}
}